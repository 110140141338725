<template>
  <div class="d-none">
    <!-- Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maxime excepturi sapiente, illum quibusdam reiciendis, explicabo perferendis voluptate distinctio cumque autem tenetur porro, asperiores laborum tempora exercitationem. Fuga provident asperiores laboriosam? -->
  </div>
</template>
<script>
import "core-js/stable";
import Vue from "vue";
export default {
  name: "Logout",

  mounted() {
    let self = this;
    Swal.fire({
      title: '<i class="fa fa-refresh fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
    });
    var FormData = require("form-data");
    var data = new FormData();
    if (this.access_token) {
      var utoken = this.access_token;
    } else {
      var utoken = localStorage.access_token;
    }
    var roles = localStorage.roles;
    var config = {
      method: "post",
      url: this.apiLink + "api/logout",
      headers: {
        Authorization: "Bearer " + utoken,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        localStorage.session = "";
        localStorage.access_token = "";
        // console.log(response.data);
        let timerInterval;
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Anda akan diarahkan ke Portal Simpel",
          timer: 2000,
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: false,
          didOpen: () => {
            timerInterval = setInterval(() => {
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector("b");
                if (b) {
                  b.textContent = Swal.getTimerLeft();
                }
              }
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then(result => {
          /* Read more about handling dismissals below */
          $("#tologin").trigger("click");
          if (result.dismiss === Swal.DismissReason.timer) {
            Vue.prototype.access_token = "";
            Vue.prototype.session = "";
            Vue.prototype.session_nama = "";
            Vue.prototype.reload_already = "yes";
            localStorage.session = "";
            localStorage.session_nama = "";
            localStorage.access_token = "";
            localStorage.reload_already = "yes";
            localStorage.clear();

            if (roles == "PEMOHON") {
              window.location.href =
                "https://perizinan.pertanian.go.id/portal-simpel/public/pemohon/dashboard";
            } else {
              window.location.href =
                "https://perizinan.pertanian.go.id/portal-simpel/public/home";
            }
          }
        });
        // alert(this.access_token);
        // setTimeout(this.redirectLogin, 1500);
      })
      .catch(error => {
        localStorage.clear();
        window.location.href =
          "https://perizinan.pertanian.go.id/portal-simpel/public/pemohon/dashboard";

        // console.log(error)
      });
  },
};
</script>
